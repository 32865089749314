/*global recurly*/
/*eslint no-undef: "error"*/

import * as Sentry from "@sentry/react"
import axios from "axios"
import CountryRegionData from "country-region-data/data"
import { Script, graphql } from "gatsby"
import parsePhoneNumberFromString, {
  getCountryCallingCode
} from "libphonenumber-js/min"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Helmet } from "react-helmet"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import styled, { ThemeProvider } from "styled-components"
import Head from "../components/Head"
import CardPaymentForm from "../components/checkout/CardPaymentForm"
import Header from "../components/checkout/Header"
import { OrderSummaryAccordion } from "../components/checkout/OrderSummary"
import SubscriptionDeliveryBox from "../components/checkout/SubscriptionDeliveryBox"
import SubscriptionOrderSummary from "../components/checkout/SubscriptionOrderSummary"
import { BrinkContext } from "../components/context/BrinkContext"
import { PageProvider } from "../components/context/PageContext"
import * as events from "../components/context/utils/events"
import { subscribeToNewsletter } from "../components/context/utils/events/klaviyo"
import { subscribeToNewsletter as pinterestTagSubscribeToNewsletter } from "../components/context/utils/events/pinterestTag"
import PrivacyPolicy from "../components/popup/PrivacyPolicy"
import TermsOfUse from "../components/popup/TermsOfUse"
import Button from "../components/ui/Button"
import Checkbox from "../components/ui/Checkbox"
import { MEDIA_MIN_LARGE } from "../constants"
import { getLocalizedCountryName } from "../helpers/countries"
import { withoutBreaklines } from "../helpers/string"
import { toDinero, toFormat } from "../helpers/toDinero"
import { removeCountryFromPath } from "../helpers/url"
import { theme } from "../theme"

const ErrorContainer = styled.div`
  color: ${(p) => p.theme.colors.errors};
  border: 0.1rem solid ${(p) => p.theme.colors.errors};
  padding: 1.5rem 2rem;
  letter-spacing: 0;
  margin-bottom: 2rem;
  width: 100%;
  line-height: 1.8rem;
`

const DesktopOnly = styled.div`
  display: none;
  ${MEDIA_MIN_LARGE} {
    display: block;
  }
`

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 7.4rem 0 4rem;
`

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: ${(p) => p.theme.colors.background};

  ${MEDIA_MIN_LARGE} {
    margin: 0 auto;
  }

  h1 {
    width: 100%;
    margin: 1rem 3rem 4rem;

    ${MEDIA_MIN_LARGE} {
      margin: 6rem 3rem 6rem;
    }
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  max-width: 55rem;

  ${MEDIA_MIN_LARGE} {
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 100%;
  }
`

const GridItem = styled.div`
  width: 100%;
  padding: 0 1.2rem;

  ${MEDIA_MIN_LARGE} {
    padding: 0;
  }
`

const Left = styled(GridItem)`
  order: 2;

  ${MEDIA_MIN_LARGE} {
    order: 1;
    width: 55rem;
    margin-right: 8rem;
  }
`

const Right = styled(GridItem)`
  order: 1;

  ${MEDIA_MIN_LARGE} {
    order: 2;
    width: 36rem;
  }
`

const Title = styled.h3`
  text-transform: initial;
  font-size: 3rem;
`

const SectionTitle = styled.p`
  text-transform: uppercase;

  span {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const Sticky = styled.div`
  height: 100%;
  max-height: calc(100vh - 7.3rem);
  padding: 1.2rem 0;

  ${MEDIA_MIN_LARGE} {
    position: sticky;
    top: 3rem;
    border: 0.1rem solid ${(p) => p.theme.colors.grey};
    width: 36rem;
    height: auto;
    padding: 1.2rem;
  }
`

const Validation = styled.div`
  color: ${(p) => p.theme.colors.errors};
  display: block;
  position: absolute;
  top: 25%;
  right: 1.2rem;
  letter-spacing: 0;
  font-size: 1.3rem;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
  }
`

const SelectContainer = styled.div`
  width: 100%;
  position: relative;

  p {
    color: ${(p) => p.theme.colors.darkGrey};
    font-size: 1.4rem;
    margin: 0;
    position: absolute;
    top: -2.2rem;
  }
`

const ContactContainer = styled.div`
  position: relative;
  > input {
    margin-bottom: 1.2rem;
  }
`

const InputContainer = styled.div`
  position: relative;
`

const Input = styled.input`
  background: ${(p) => p.theme.colors.white};
  border: none;
  ${(p) => {
    return p.borders.map(
      (border) =>
        "border-" +
        border +
        ": 0.1rem solid " +
        (p.hasError ? p.theme.colors.errors : p.theme.colors.grey) +
        ";"
    )
  }}
  width: 100%;
  text-transform: initial;
  height: 4.5rem;
  padding-left: 1rem;

  &:focus {
    outline: none;
    border: 0.1rem solid ${(p) => p.theme.colors.black};
  }

  &::placeholder {
    color: ${(p) => p.theme.colors.darkGrey};
  }
`

const Select = styled.select`
  background: ${(p) => p.theme.colors.white};
  color: ${(p) => p.theme.colors.darkGrey};
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  font-size: 2rem;
  border-radius: 0.2rem;

  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNCAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODAzOTA2IDYuNDJMNi40NDM5MSAxMi4wNlYtOS41MzY3NGUtMDdINy41NjM5MVYxMi4wNkwxMy4yMDM5IDYuNDJMMTQuMDAzOSA3LjJMNy4wMDM5MSAxNC4yTDAuMDAzOTA2MjYgNy4yTDAuODAzOTA2IDYuNDJaIiBmaWxsPSIjOUE5QTlBIi8+Cjwvc3ZnPgoK");
  background-repeat: no-repeat;
  background-position: right 1.2rem top 50%;
  background-size: 1.2rem auto;
`

const SelectOption = styled.option`
  font-size: 1.2rem;
  background: pink;
  padding: 2rem;
  position: absolute;
  top: 0;
`

const ContinueButton = styled(Button)`
  width: 100%;
  margin-top: 4rem;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  transition: all 0.2s;
  text-transform: initial;
  font-size: 2rem;
  height: 4.5rem;
  padding: 0 2.5rem;

  &:disabled {
    background: ${(p) => p.theme.colors.darkGrey};
  }

  &:hover {
    background: ${(p) => p.theme.colors.secondaryOpac};
    font-variation-settings: "wght" 700, "wdth" 700;
  }
`

const OrderSummaryWrapper = styled.div`
  display: block;
  background: white;
  z-index: 1;

  h3 {
    margin: 0;
  }

  ${MEDIA_MIN_LARGE} {
    display: none;
  }
`

const TermsAndConditions = styled.p`
  margin: 0;
  margin-top: 2.4rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: ${(p) => p.theme.colors.black};
  padding-bottom: 2rem;
`

const TermsLink = styled.span`
  text-transform: none;
  color: ${(p) => p.theme.colors.black};
  font-variation-settings: "wght" 750;
  cursor: pointer;
`

const CheckboxLabel = styled.span`
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.black};
  margin-left: 1.2rem;
`

const CHECKOUT_NEWSLETTER_LIST_ID = "VFv9DU"

const SubscribePage = ({ data: { sanityProduct }, pageContext, location }) => {
  const { t } = useTranslation("translations")
  const { languageCode, currentStore, setCurrentStore, countryWhiteList } =
    useContext(BrinkContext)

  const [regions, setRegions] = useState([])
  const [recurlyInstance, setRecurlyInstance] = useState(null)
  const [displayError, setDisplayError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [cardElements, setCardElements] = useState(null)
  const [cardNumberElement, setCardNumberElement] = useState(null)
  const [cardCvvElement, setCardCvvElement] = useState(null)
  const [cardType, setCardType] = useState(null)
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
  const [newsletterAccepted, setNewsletterAccepted] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur"
  })

  const { sanityCheckoutTitle } = pageContext

  const queryParams = new URLSearchParams(location.search)
  const frequency = queryParams.get("s")

  const variantId = queryParams.get("v")
  const [variant, setVariant] = useState(
    sanityProduct.variants.find((v) => v._id === variantId) ??
      sanityProduct.variants[0]
  )
  const priceDinero = toDinero(
    parseInt(
      variant?.subscriptionInitialFee?.[currentStore.currencyUnit] ??
        variant.subscriptionVariant.price[currentStore.currencyUnit] * 100
    ),
    currentStore.currencyUnit
  )
  const price = toFormat(priceDinero)

  const selectedSubscriptionItem = useMemo(
    () =>
      variant.subscriptionItems.find(
        (item) => item.recurlyPlanCode === frequency
      ),
    [variant, frequency]
  )

  useEffect(() => {
    triggerInitiateCheckoutEvent()
  }, [])

  useEffect(() => {
    if (["US", "CA"].includes(currentStore.countryCode)) {
      const { regions } = CountryRegionData.find(
        (r) => r.countryShortCode === currentStore.countryCode
      )
      setRegions(regions)
    } else {
      setRegions([])
    }
  }, [currentStore.countryCode])

  const isBrowser = typeof window !== `undefined`

  useEffect(() => {
    if (isBrowser) window.Freshbots?.deActivate()
  }, [isBrowser])

  useEffect(() => {
    if (recurlyInstance) {
      setTimeout(() => {
        const elements = recurly.Elements()
        const cardNumber = elements.CardNumberElement({
          style: {
            fontWeight: "lighter",
            placeholder: {
              content: "1234 5678 9012 3456"
            }
          }
        })
        const cardMonth = elements.CardMonthElement({
          style: {
            fontWeight: "lighter",
            placeholder: {
              content: "MM"
            }
          }
        })
        const cardYear = elements.CardYearElement({
          style: {
            fontWeight: "lighter",
            placeholder: {
              content: "YY"
            }
          }
        })
        const cardCvv = elements.CardCvvElement({
          style: {
            fontWeight: "lighter",
            placeholder: {
              content: t("digits", { number: cardType === "amex" ? 4 : 3 })
            }
          }
        })
        cardNumber.attach("#recurly-number-element")
        cardMonth.attach("#recurly-month-element")
        cardYear.attach("#recurly-year-element")
        cardCvv.attach("#recurly-cvv-element")
        setCardNumberElement(cardNumber)
        setCardCvvElement(cardCvv)
        setCardElements(elements)
      }, 100)
    }
    return () => {
      if (cardElements) {
        cardElements.remove()
      }
    }
  }, [recurlyInstance])

  useEffect(() => {
    if (cardNumberElement) {
      cardNumberElement.on("change", changeHandler)
      return () => {
        cardNumberElement.off("change", changeHandler)
      }
    }
  }, [cardNumberElement])

  const recurlyLoaded = () => {
    recurly.configure(process.env.GATSBY_RECURLY_PUBLIC_KEY)
    setRecurlyInstance(recurly)
  }

  const changeHandler = (event) => {
    const brandMap = {
      visa: "visa",
      master: "mc",
      american_express: "amex",
      union_pay: "cup",
      diners_club: "diners",
      discover: "discover",
      jcb: "jcb"
    }
    if (event.brand !== "unknown") {
      setCardType(brandMap[event.brand])
    } else {
      setCardType(null)
    }
    cardCvvElement.configure({
      style: {
        placeholder: {
          content: t("digits", {
            number: brandMap[event.brand] === "amex" ? 4 : 3
          })
        }
      }
    })
  }

  const validatePhoneNumber = (data) => {
    const phoneNumber = parsePhoneNumberFromString(
      data || "",
      currentStore.countryCode
    )
    const isPossible = phoneNumber ? phoneNumber.isPossible() : false
    return isPossible || t("Invalid phone number")
  }

  const onCountryChange = (event) => {
    const country = event.target.value
    setCurrentStore((s) => ({ ...s, countryCode: country }))
    setValue("postalCode", "")
    const location = window.location.pathname
    const currentPathnameAsArray = location.match(/\/([a-zA-Z-]*)/g)
    const currentCountry = currentPathnameAsArray[0]
    window.location.pathname = location.replace(
      currentCountry,
      `/${country.toLowerCase()}`
    )
  }

  const onRegionChange = (event) => {
    const region = event.target.value
    setValue("region", region)
  }

  const handleBlurEvent = (event) => {
    if (event.target?.value) {
      const email = event.target.value
      events.identify({
        email
      })
      onEmailEntered(email)
    }
  }

  const onEmailEntered = (email) => {
    events.addPersonalDetails(email, null, currentStore.countryCode)
  }

  const triggerInitiateCheckoutEvent = () => {
    const slug = sanityProduct.slug.current
    const pricing =
      variant?.subscriptionInitialFee ?? variant.subscriptionVariant.price
    const priceMap = Object.keys(pricing).reduce((acc, key) => {
      acc[key] = parseInt(
        variant?.subscriptionInitialFee?.[key] ??
          variant.subscriptionVariant.price[key] * 100
      )
      return acc
    }, {})
    events.initiateCheckout(
      {
        id: `${slug}-${frequency}`,
        store: currentStore,
        totalPriceWithDiscount: priceMap[currentStore.currencyUnit],
        totalPrice: priceMap[currentStore.currencyUnit],
        cartItems: [
          {
            id: variant._id,
            type: "productVariant",
            name: withoutBreaklines(variant.displayName?.en ?? ""),
            slug: variant.slug.current,
            quantity: 1,
            price: priceMap,
            imageUrl: variant.mainImage?.asset?.url ?? ""
          }
        ],
        discounts: {
          productDiscounts: []
        }
      },
      currentStore,
      undefined,
      undefined,
      true
    )
  }

  const handleTracking = (order, email) => {
    try {
      events.purchase({
        order: null,
        recurlyOrder: order,
        currentStore,
        email
      })
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
  }

  const handleNewsletterSubscription = (email) => {
    if (newsletterAccepted) {
      subscribeToNewsletter(
        currentStore.countryCode,
        email,
        CHECKOUT_NEWSLETTER_LIST_ID
      )
      pinterestTagSubscribeToNewsletter()
      events.signup({
        email: email,
        pageLocation: removeCountryFromPath(location.pathname + location.search)
      })
    }
  }

  const callRecurly = async (data) => {
    const res = await axios({
      method: "post",
      url: `/.netlify/functions/createpurchase`,
      data
    })
    if (res.data.error) {
      if (
        res.data.error.type === "transaction" &&
        res.data.error.transaction_error?.code ===
          "three_d_secure_action_required"
      ) {
        const risk = recurly.Risk()
        const threeDSecure = risk.ThreeDSecure({
          actionTokenId:
            res.data.error.transaction_error.three_d_secure_action_token_id
        })
        let secureElement = document.querySelector("#recurly-3ds")
        secureElement.style.height = "400px"
        threeDSecure.attach(secureElement)
        threeDSecure.on("error", (err) => {
          console.error("3DS error", err)
          setDisplayError(true)
          secureElement.style.height = "0px"
        })

        threeDSecure.on("token", async (token) => {
          secureElement.style.height = "0px"
          await callRecurly({
            ...data,
            three_d_secure_action_result_token_id: token.id
          })
        })
      } else {
        setDisplayError(true)
      }
    } else {
      setIsSuccess(true)
      setDisplayError(false)
      if (res.data?.charge_invoice?.id) {
        handleTracking(res.data.charge_invoice, data.email)
        handleNewsletterSubscription(data.email)
      }
    }
  }

  const onPaymentSubmit = () => {
    const data = getValues()
    recurly.token(
      cardElements,
      document.querySelector("#recurly-form"),
      async function (err, token) {
        if (err) {
          console.error(err)
          setDisplayError(true)
          return
        }
        try {
          callRecurly({
            currency: currentStore.currencyUnit,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            frequency,
            futureStart: selectedSubscriptionItem?.futureStart ?? 0,
            initialPurchase: variant.initialPurchaseVariant?._id ?? null,
            token: token.id,
            subscriptionVariant: variant.subscriptionVariant._id,
            discountCode: data.discountCode
          })
        } catch (error) {
          console.error(error)
          setDisplayError(true)
        }
      }
    )
  }

  return (
    <ThemeProvider theme={theme(pageContext.sanityTheme)}>
      <PageProvider pageContext={pageContext}>
        <Wrapper className="top">
          <Script
            src="https://js.recurly.com/v4/recurly.js"
            onLoad={recurlyLoaded}
          />
          <Helmet>
            <html lang="en" className="noOverflow" />
            <body className="noOverflow" />
            <link
              href="https://js.recurly.com/v4/recurly.css"
              rel="stylesheet"
              type="text/css"
            ></link>
          </Helmet>
          <Head
            meta={{
              title:
                sanityCheckoutTitle.title[languageCode] ||
                sanityCheckoutTitle.title.en
            }}
          />
          <Header />
          <OrderSummaryWrapper>
            <OrderSummaryAccordion
              title={t("Your order")}
              price={price}
              defaultOpen
            >
              <SubscriptionOrderSummary
                product={sanityProduct}
                variant={variant}
                frequency={frequency}
                onDiscountCodeChange={(discountCode) => {
                  setValue("discountCode", discountCode)
                }}
              />
            </OrderSummaryAccordion>
          </OrderSummaryWrapper>
          <Body>
            <Grid>
              <Left>
                {isSuccess ? (
                  <>
                    <Title>
                      {t("subscriptionSuccessTitle", {
                        firstName: getValues().firstName
                      })}
                    </Title>
                    <p>{t("subscriptionSuccessDescription")}</p>
                    <a href="/">
                      <ContinueButton>{t("Continue shopping")}</ContinueButton>
                    </a>
                  </>
                ) : (
                  <>
                    <Title>{t("Checkout")}</Title>
                    <SectionTitle>{t("contact")}</SectionTitle>
                    <ContactContainer>
                      <Input
                        {...register("email", {
                          required: t("This is a required field"),
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
                            message: t("Please supply a valid e-mail address")
                          }
                        })}
                        type="email"
                        placeholder={t("E-mail")}
                        borders={"left,top,right,bottom".split(",")}
                      />
                      {errors.email && (
                        <Validation>{errors.email.message}</Validation>
                      )}
                      <Checkbox
                        name="newsletter"
                        value={newsletterAccepted}
                        onChange={(e) => {
                          setNewsletterAccepted(e.target.checked)
                        }}
                      >
                        <CheckboxLabel>
                          {t("checkout-newsletter-signup-label")}
                        </CheckboxLabel>
                      </Checkbox>
                    </ContactContainer>
                    <SectionTitle>{t("Shipping")}</SectionTitle>
                    <SubscriptionDeliveryBox variant={variant} />
                    <SectionTitle>{t("delivery-address")}</SectionTitle>
                    <form
                      id="recurly-form"
                      onSubmit={handleSubmit(onPaymentSubmit)}
                    >
                      <SelectContainer>
                        <Select
                          defaultValue={currentStore.countryCode}
                          onChange={onCountryChange}
                          data-recurly="country"
                        >
                          {countryWhiteList
                            ?.map((countryCode) => {
                              return (
                                <SelectOption
                                  value={countryCode}
                                  key={countryCode}
                                >
                                  {getLocalizedCountryName(
                                    countryCode,
                                    languageCode
                                  )}
                                </SelectOption>
                              )
                            })
                            .sort((a, b) =>
                              getLocalizedCountryName(
                                a.countryCode,
                                a.languageCode
                              )?.localeCompare(
                                getLocalizedCountryName(
                                  b.countryCode,
                                  b.languageCode
                                )
                              )
                            )}
                        </Select>
                      </SelectContainer>
                      <Row>
                        <InputContainer>
                          <Input
                            {...register("firstName", {
                              required: t("This is a required field")
                            })}
                            placeholder={t("First name")}
                            borders={"left,right,".split(",")}
                            data-recurly="first_name"
                          />
                          {errors.firstName && (
                            <Validation>{errors.firstName.message}</Validation>
                          )}
                        </InputContainer>
                        <InputContainer>
                          <Input
                            {...register("lastName", {
                              required: t("This is a required field")
                            })}
                            placeholder={t("Last name")}
                            borders={"right".split(",")}
                            data-recurly="last_name"
                          />
                          {errors.lastName && (
                            <Validation>{errors.lastName.message}</Validation>
                          )}
                        </InputContainer>
                      </Row>
                      <InputContainer>
                        <Input
                          {...register("phone", {
                            required: t("This is a required field"),
                            validate: (data) => validatePhoneNumber(data)
                          })}
                          type="tel"
                          placeholder={`${t(
                            "Telephone"
                          )} (+${getCountryCallingCode(
                            currentStore.countryCode
                          )})`}
                          borders={"left,top,right".split(",")}
                          data-recurly="phone"
                        />
                        {errors.phone && (
                          <Validation>{errors.phone.message}</Validation>
                        )}
                      </InputContainer>
                      <InputContainer>
                        <Input
                          {...register("address", {
                            required: t("This is a required field")
                          })}
                          placeholder={t("Address with house number")}
                          borders={"left,top,right".split(",")}
                          data-recurly="address1"
                        />
                        {errors.address && (
                          <Validation>{errors.address.message}</Validation>
                        )}
                      </InputContainer>
                      <InputContainer>
                        <Input
                          {...register("houseNumberOrName")}
                          placeholder={t("House number field")}
                          borders={"left,top,right".split(",")}
                          data-recurly="address2"
                        />
                        {errors.houseNumberOrName && (
                          <Validation>
                            {errors.houseNumberOrName.message}
                          </Validation>
                        )}
                      </InputContainer>
                      <Row>
                        <InputContainer>
                          <Input
                            {...register("postalCode", {
                              required: t("This is a required field")
                            })}
                            placeholder={t("Zip code")}
                            borders={
                              regions.length
                                ? "left,top,right".split(",")
                                : "left,top,right,bottom".split(",")
                            }
                            data-recurly="postal_code"
                          />
                          {errors.postalCode && (
                            <Validation>{errors.postalCode.message}</Validation>
                          )}
                        </InputContainer>
                        <InputContainer>
                          <Input
                            {...register("city", {
                              required: t("This is a required field")
                            })}
                            placeholder={t("City")}
                            borders={
                              regions.length
                                ? "top,right".split(",")
                                : "top,right,bottom".split(",")
                            }
                            data-recurly="city"
                          />
                          {errors.city && (
                            <Validation>{errors.city.message}</Validation>
                          )}
                        </InputContainer>
                        {regions.length ? (
                          <Select
                            data-recurly="state"
                            onChange={onRegionChange}
                          >
                            <option value="">{t("Select state")}</option>
                            {regions.map((r) => {
                              return (
                                <option value={r.shortCode} key={r.shortCode}>
                                  {r.name}
                                </option>
                              )
                            })}
                          </Select>
                        ) : null}
                      </Row>
                      {displayError && (
                        <ErrorContainer>
                          {t(
                            "Something went wrong with your payment. Please try another option or contact our support."
                          )}
                        </ErrorContainer>
                      )}
                      <SectionTitle>{t("Payment")}</SectionTitle>
                      <div
                        style={{ marginTop: "2rem", marginBottom: "2rem" }}
                        id="recurly-3ds"
                      ></div>
                      <CardPaymentForm selectedCardType={null} />
                      <ContinueButton disabled={!isValid} type="submit">
                        {t("Place order")}
                      </ContinueButton>
                    </form>
                    <TermsAndConditions>
                      {t("By continuing you agree to COMIS’")}{" "}
                      <TermsLink onClick={() => setShowTermsOfUse(true)}>
                        {t("Terms and Conditions")}{" "}
                      </TermsLink>
                      {t(
                        "and understand that we will process your data on the basis of our "
                      )}
                      <TermsLink onClick={() => setShowPrivacyPolicy(true)}>
                        {t("Privacy Policy")}
                      </TermsLink>
                      .
                    </TermsAndConditions>
                    <TermsOfUse
                      openPopup={showTermsOfUse}
                      setOpenPopup={setShowTermsOfUse}
                    />
                    <PrivacyPolicy
                      openPopup={showPrivacyPolicy}
                      setOpenPopup={setShowPrivacyPolicy}
                    />
                  </>
                )}
              </Left>
              <Right>
                <DesktopOnly>
                  <Title style={{ marginLeft: "1rem" }}>
                    {t("Your order")}
                  </Title>
                  <Sticky>
                    <SubscriptionOrderSummary
                      product={sanityProduct}
                      variant={variant}
                      frequency={frequency}
                      onDiscountCodeChange={(discountCode) => {
                        setValue("discountCode", discountCode)
                      }}
                    />
                  </Sticky>
                </DesktopOnly>
              </Right>
            </Grid>
          </Body>
        </Wrapper>
      </PageProvider>
    </ThemeProvider>
  )
}

export default SubscribePage

export const query = graphql`
  query ($sanityProductId: String!) {
    sanityProduct(_id: { eq: $sanityProductId }) {
      ...Product
    }
  }
`
