import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { MEDIA_MIN_LARGE } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import * as events from "../context/utils/events"
import Radio from "../ui/Radio"

const DeliveryBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid ${(p) => p.theme.colors.black};
  padding: 1.2rem;
  height: 7rem;
`

const DeliveryLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
`

const DeliveryRight = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
`

const RadioButton = styled(Radio)`
  align-items: start;
  margin: 0;
  margin-top: 0.6rem;
  > input {
    margin: 0;
  }
`

const DeliveryText = styled.div`
  display: flex;
  flex-direction: column;
`

const DeliveryTitle = styled.h4`
  margin: 0;
  font-size: 2rem;
  color: ${(p) => p.theme.colors.black};
`

const DeliverySubtitle = styled.p`
  margin: 0;
  font-size: 1.6rem;
  color: ${(p) => p.theme.colors.darkGrey};
`

const DeliveryPrice = styled.p`
  margin: 0;
  font-size: 2rem;
`

const DeliveryIcon = styled.img`
  display: none;

  ${MEDIA_MIN_LARGE} {
    display: block;
    width: 3rem;
    height: 3rem;
  }
`

const SubscriptionDeliveryBox = ({ variant }) => {
  const { t } = useTranslation()
  const { currentStore } = useContext(BrinkContext)

  useEffect(() => {
    const subtotal = parseInt(
      variant?.subscriptionInitialFee?.[currentStore.currencyUnit] ??
        variant.subscriptionVariant.price[currentStore.currencyUnit] * 100
    )

    events.addShipping(
      {
        store: currentStore,
        totalPriceWithDiscount: subtotal,
        cartItems: [
          {
            type: "shippingOption",
            price: {
              SEK: 0,
              EUR: 0,
              DKK: 0,
              NOK: 0,
              GBP: 0,
              CHF: 0,
              PLN: 0,
              HUF: 0,
              CZK: 0,
              USD: 0
            }
          }
        ]
      },
      { label: "Home delivery" }
    )
  }, [])

  return (
    <DeliveryBox>
      <DeliveryLeft>
        <RadioButton preSelected={true} />
        <DeliveryText>
          <DeliveryTitle>{t("home-delivery")}</DeliveryTitle>
          <DeliverySubtitle>{t("to-your-mailbox")}</DeliverySubtitle>
        </DeliveryText>
      </DeliveryLeft>
      <DeliveryRight>
        <DeliveryPrice>{t("Free")}</DeliveryPrice>
        <DeliveryIcon
          decoding="async"
          loading="lazy"
          src="https://cdn.sanity.io/images/zlwmx92g/production/ada6c5683d7ae050ec8f7ef8883c12cc1ba15acc-30x30.svg?w=30&amp;h=30&amp;auto=format"
          alt="Home delivery"
        />
      </DeliveryRight>
    </DeliveryBox>
  )
}

export default SubscriptionDeliveryBox
