import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Radio from "../ui/Radio"

const Container = styled.div`
  border: 0.1rem solid ${(p) => p.theme.colors.black};
  padding: 1.2rem;
  margin-bottom: 2rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
`

const InsideRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
`

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
`

const RecurlyInput = styled.div`
  width: 100%;
  font-family: "Garaje Flex", "sans-serif";

  .recurly-element {
    background-color: ${(p) => p.theme.colors.inputBackground};
    border: 0.1rem solid ${(p) => p.theme.colors.grey};
    border-radius: 0.2rem;
    margin-top: 0;
  }

  .recurly-element-number {
    width: 100% !important;
  }
  .recurly-element-cvv {
    width: 100% !important;
  }
`

const Label = styled.label`
  display: flex;
  transition: all 0.2s;
  position: absolute;
  color: ${(p) => p.theme.colors.darkGrey};
  margin: 0;
  font-size: 1.4rem;
  top: -2.2rem;
`

const Svg = styled.svg`
  position: absolute !important;
  right: 1rem;
  top: 25%;
`

const RadioLabel = styled(Radio)`
  margin: 0;
  margin-bottom: 2rem;
  display: flex;
  gap: 0.5rem;

  input {
    margin: 0;
  }
`

const ProviderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
  gap: 0.3rem;
`

const Provider = styled.img`
  height: 1.6rem;
  width: 2.4rem;
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  border-radius: 0.2rem;
`

const CardPaymentForm = ({ selectedCardType }) => {
  const { t } = useTranslation("translations")

  const cardLogos = [
    {
      name: "visa",
      alt: "visa"
    },
    {
      name: "mc",
      alt: "mastercard"
    },
    {
      name: "amex",
      alt: "amex"
    },
    {
      name: "cup",
      alt: "unionpay"
    },
    {
      name: "diners",
      alt: "dinersclub"
    },
    {
      name: "discover",
      alt: "discover"
    },
    {
      name: "jcb",
      alt: "jcb"
    }
  ]

  return (
    <Container>
      <RadioLabel preSelected>{t("Credit or debit card")}</RadioLabel>
      <InputContainer>
        <RecurlyInput id="recurly-number-element" />
        <Label>{t("Card number")}</Label>
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="18"
          fill="none"
          viewBox="0 0 27 18"
        >
          <path
            fill="#E6E9EB"
            d="M0 3a3 3 0 0 1 3-3h21a3 3 0 0 1 3 3v12a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3z"
          />
          <path fill="#B9C4C9" d="M4 12h19v2H4z" />
          <rect width="4" height="4" x="4" y="4" fill="#fff" rx="1" />
        </Svg>
      </InputContainer>
      <ProviderContainer>
        {cardLogos.map((logo) => {
          return (
            <Provider
              key={logo.name}
              src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${logo.name}.svg`}
              alt={logo.alt}
              style={{
                opacity:
                  selectedCardType === logo.name || selectedCardType === null
                    ? 1
                    : 0.2
              }}
            />
          )
        })}
      </ProviderContainer>
      <Row>
        <InsideRow>
          <InputContainer>
            <RecurlyInput id="recurly-month-element" />
            <Label>{t("Expiry date")}</Label>
          </InputContainer>
          <InputContainer>
            <RecurlyInput id="recurly-year-element" />
          </InputContainer>
        </InsideRow>
        <InputContainer>
          <RecurlyInput id="recurly-cvv-element" />
          <Label>{t("cvc_cvv")}</Label>
          <Svg
            width="27"
            height="18"
            viewBox="0 0 27 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 4.00001V3.37501C27 2.4799 26.6444 1.62146 26.0115 0.988518C25.3786 0.355581 24.5201 0 23.625 0H3.375C2.47989 0 1.62145 0.355581 0.988514 0.988518C0.355579 1.62146 0 2.4799 0 3.37501V4.00001H27Z"
              fill="#E6E9EB"
            ></path>
            <path
              d="M0 6.99994V14.6666C0 15.5507 0.355579 16.3985 0.988514 17.0237C1.62145 17.6488 2.47989 18 3.375 18H23.625C24.5201 18 25.3786 17.6488 26.0115 17.0237C26.6444 16.3985 27 15.5507 27 14.6666V6.99994H0Z"
              fill="#E6E9EB"
            ></path>
            <rect y="4.00012" width="27" height="3.00001" fill="#687282"></rect>
            <path
              d="M4 11C4 10.4477 4.44772 10 5 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H5C4.44771 14 4 13.5523 4 13V11Z"
              fill="white"
            ></path>
            <rect
              x="16.5"
              y="9.5"
              width="7"
              height="5"
              rx="2.5"
              stroke="#C12424"
            ></rect>
          </Svg>
        </InputContainer>
      </Row>
    </Container>
  )
}

export default CardPaymentForm
