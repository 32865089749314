import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../../constants"
import { addMonthsToDate } from "../../helpers/date"
import { withoutBreaklines } from "../../helpers/string"
import { toDinero, toFormat } from "../../helpers/toDinero"
import { BrinkContext } from "../context/BrinkContext"
import Price from "../product/Price"
import Button from "../ui/Button"
import TextWithLineBreaks from "../ui/TextWithLineBreaks"

const Wrapper = styled.div`
  z-index: 10003;
  position: relative;
`

const Container = styled.div`
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  padding-bottom: 1rem;
  padding-top: 1rem;

  &:first-of-type {
    padding-top: 0;
  }
`

const OrderSubtitle = styled.p`
  margin-block: 0;
  font-variation-settings: "wght" 700, "wdth" 700;
`

const ShipNowItemWrapper = styled.div`
  margin-block: 1.2rem;
  display: flex;
  flex-direction: row;
`

const ShipNowItemInformation = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    margin-block: 0;
    font-size: 1.6rem;
  }

  p {
    margin-block: 0;
    margin-inline: 1.2rem;
    font-size: 1.6rem;
  }
`

const ShipNowItemName = styled.h2`
  margin: 0;
  font-size: 2rem;
  font-variation-settings: "wght" 500;
  text-align: left;
  margin-inline: 1rem;
`

const ShipNowItemSubName = styled.span`
  font-size: 2rem;
  font-variation-settings: "wght" 500;
  margin-inline: 1rem;
  text-align: left;
`

const ShipNowItemDescription = styled.p`
  margin-top: 0.6rem !important;
  line-height: 2rem;
`

const ShipNowItemPrice = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2rem;

  span {
    margin-right: 0.4rem;
  }

  ${MEDIA_MIN_LARGE} {
    font-size: 2rem;
  }
`

const ShipNowItemCurrentPrice = styled.p`
  text-align: right;
  margin: 0;
`

const ShipNowItemOldPrice = styled.p`
  text-align: right;
  margin: 0;
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
`

const FormattedPrice = styled(Price)`
  font-size: 2rem;
`

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
`

const ImageContainer = styled.div``

const Image = styled(GatsbyImage)`
  width: 10rem;
  height: auto;

  ${MEDIA_MIN_MEDIUM} {
    width: 15rem;
    height: auto;
  }

  ${MEDIA_MIN_LARGE} {
    width: 9rem;
    height: auto;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 0.1rem;
  color: ${(p) => p.theme.colors.black};
`
const TotalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const GrandTotal = styled(Row)`
  z-index: 1;
  background-color: ${(p) => p.theme.colors.background};

  p {
    color: ${(p) => p.theme.colors.black};
    font-size: 2rem;
    font-variation-settings: "wght" 700;
    border-bottom: none;
    text-transform: uppercase;
    margin-top: 2rem;
    margin-bottom: 0;
  }
`

const Text = styled.div`
  color: ${(p) => p.theme.colors.darkGrey};
  font-size: 1.6rem;
`

const Line = styled(Row)`
  flex-direction: row;
  margin: 0;
`

const Label = styled.span`
  font-size: 2rem;
  padding: 0.2rem 0;
`

const DiscountContainer = styled.div`
  width: 100%;
  margin-top: 2.4rem;
  margin-bottom: 1.2rem;
  position: relative;

  form {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
  }

  p {
    margin: 0;
  }
`

const DiscountText = styled.p`
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: ${(p) => p.theme.colors.darkGrey};
  cursor: pointer;

  &:hover {
    color: ${(p) => p.theme.colors.black};
  }
`

const DiscountField = styled.input`
  width: calc(50% - 0.6rem) !important;
  background: ${(p) =>
    p.light ? p.theme.colors.white : p.theme.colors.inputBackground};
  border: 0.1rem solid ${(p) => p.theme.colors.grey};
  width: 100%;
  text-transform: initial;
  border-radius: 0.2rem;
  height: 4.5rem;
  padding-left: 1rem;

  &:focus {
    outline: none;
    border: 0.1rem solid ${(p) => p.theme.colors.black};

    + label {
      color: ${(p) => p.theme.colors.black};
    }
  }

  &::placeholder {
    color: ${(p) => (p.light ? p.theme.colors.darkGrey : p.theme.colors.black)};
  }
`

const DiscountButton = styled(Button)`
  width: calc(50% - 0.6rem);
  background: ${(p) => p.theme.colors.black};
  transition all: 0.2s;

  &:disabled {
    background: ${(p) => p.theme.colors.darkGrey};
  }
  &:hover {
    font-variation-settings: "wght" 700, "wdth" 700;
  }
`

const ActiveDiscounts = styled.div`
  width: 100%;
`

const ActiveDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(p) => p.theme.colors.lightGrey};
  width: 100%;
  margin: 0;
  padding: 1.2rem 1.2rem 1rem;

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
`

const DiscountErrorLabel = styled.span`
  color: ${(p) => p.theme.colors.errors};
  font-size: 1.6rem;
`

const FreePrice = styled.div`
  text-align: right;
  font-size: 2rem;
`

const ShipsLaterContainer = styled.div`
  margin-top: 2.4rem;
  color: ${(p) => p.theme.colors.darkGrey};
`

const SubscriptionOrderSummary = ({
  product,
  variant,
  frequency,
  onDiscountCodeChange
}) => {
  const { t } = useTranslation("translations")
  const [showDiscountInput, setShowDiscountInput] = useState(false)
  const [discountCode, setDiscountCode] = useState("")
  const [subtotalPrice, setSubtotalPrice] = useState("")

  const { currentStore, languageCode } = useContext(BrinkContext)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
    reset,
    setError
  } = useForm({
    defaultValues: {
      code: ""
    },
    mode: "onChange"
  })

  const selectedItem = frequency
    ? variant.subscriptionItems.find(
        (item) => item.recurlyPlanCode === frequency
      )
    : variant.subscriptionItems[0]

  const subscriptionItemDescription = t(
    "Summary subscription item description",
    {
      frequency: t("shipsEvery_" + selectedItem?.frequency),
      price: toFormat(
        toDinero(
          parseInt(
            variant.subscriptionVariant.price[currentStore.currencyUnit] * 100
          ),
          currentStore.currencyUnit
        )
      ),
      date: addMonthsToDate(
        new Date(),
        selectedItem?.frequency ?? 0
      ).toLocaleDateString(languageCode)
    }
  )

  const priceDinero = toDinero(
    parseInt(
      variant?.subscriptionInitialFee?.[currentStore.currencyUnit] ??
        variant.subscriptionVariant.price[currentStore.currencyUnit] * 100
    ),
    currentStore.currencyUnit
  )
  const price = toFormat(priceDinero)

  const oldPriceDinero = toDinero(
    parseInt(variant.price[currentStore.currencyUnit] * 100),
    currentStore.currencyUnit
  )
  const oldPrice = toFormat(oldPriceDinero)

  useEffect(() => {
    if (discountCode) {
      onDiscountCodeChange(discountCode)
      setSubtotalPrice(
        toFormat(
          toDinero(
            parseInt(
              variant.subscriptionInitialFee[currentStore.currencyUnit]
            ) +
              parseInt(
                variant.subscriptionGiftedVariant.price[
                  currentStore.currencyUnit
                ]
              ) *
                100,
            currentStore.currencyUnit
          )
        )
      )
    } else {
      onDiscountCodeChange("")
      setSubtotalPrice(
        toFormat(
          toDinero(
            parseInt(
              variant?.subscriptionInitialFee?.[currentStore.currencyUnit] ??
                variant.subscriptionVariant.price[currentStore.currencyUnit] *
                  100
            ),
            currentStore.currencyUnit
          )
        )
      )
    }
  }, [discountCode])

  const addCode = (data) => {
    const availableCodes =
      process.env.GATSBY_SUBSCRIPTION_PROMO_CODES.split(",")
    const isValidCode = availableCodes.includes(data.code?.toLowerCase() ?? "")
    if (!isValidCode) {
      setError("code", {
        type: "manual",
        message: t("Invalid promo code")
      })
      return
    }
    if (!variant?.subscriptionGiftedVariant) {
      setError("code", {
        type: "manual",
        message: t("Invalid promo code")
      })
      return
    }
    setDiscountCode(data.code.toLowerCase())
    reset()
  }

  const removeCode = () => {
    setDiscountCode("")
    reset()
    setShowDiscountInput(false)
  }

  return (
    <Wrapper>
      <Container>
        <OrderSubtitle>{t("SHIPS TODAY")}</OrderSubtitle>
        <ShipNowItemWrapper>
          <ImageContainer>
            <Image image={getImage(variant.mainImage.asset)} alt="" />
          </ImageContainer>
          <FlexContainer>
            <ShipNowItemInformation>
              <ShipNowItemName>
                <TextWithLineBreaks text={variant.displayName[languageCode]} />
              </ShipNowItemName>
              {variant.displaySubName?.[languageCode] ? (
                <ShipNowItemSubName>
                  {variant.displaySubName[languageCode]}
                </ShipNowItemSubName>
              ) : null}
            </ShipNowItemInformation>
            <ShipNowItemPrice>
              <ShipNowItemCurrentPrice>
                <span>{price}</span>
              </ShipNowItemCurrentPrice>
              <ShipNowItemOldPrice>
                <span>{oldPrice}</span>
              </ShipNowItemOldPrice>
            </ShipNowItemPrice>
          </FlexContainer>
        </ShipNowItemWrapper>
        {discountCode && variant.subscriptionGiftedVariant ? (
          <ShipNowItemWrapper>
            <ImageContainer>
              <Image
                image={getImage(
                  variant.subscriptionGiftedVariant.mainImage.asset
                )}
                alt=""
              />
            </ImageContainer>
            <FlexContainer>
              <ShipNowItemInformation>
                <ShipNowItemName>
                  {withoutBreaklines(
                    variant.subscriptionGiftedVariant.displayName[languageCode]
                  )}
                </ShipNowItemName>
              </ShipNowItemInformation>
              <ShipNowItemPrice>
                <FreePrice>
                  <span>{t("Free")}</span>
                </FreePrice>
                <ShipNowItemOldPrice>
                  <FormattedPrice
                    price={variant.subscriptionGiftedVariant.price}
                    multiply
                  />
                </ShipNowItemOldPrice>
              </ShipNowItemPrice>
            </FlexContainer>
          </ShipNowItemWrapper>
        ) : null}
        <ShipsLaterContainer>
          <OrderSubtitle>
            {t("shipsIn", { count: selectedItem?.frequency })}
          </OrderSubtitle>
          <ShipNowItemWrapper>
            <ImageContainer>
              <Image
                image={getImage(variant.subscriptionVariant.mainImage.asset)}
                alt=""
              />
            </ImageContainer>
            <FlexContainer>
              <ShipNowItemInformation>
                <ShipNowItemName>
                  {selectedItem?.productName[languageCode] ?? ""}
                </ShipNowItemName>
                {selectedItem?.productSubName?.[languageCode] ? (
                  <ShipNowItemSubName>
                    {selectedItem.productSubName[languageCode]}
                  </ShipNowItemSubName>
                ) : null}
                <ShipNowItemDescription>
                  {subscriptionItemDescription}
                </ShipNowItemDescription>
              </ShipNowItemInformation>
            </FlexContainer>
          </ShipNowItemWrapper>
        </ShipsLaterContainer>
      </Container>
      <Container>
        <Line>
          <Label>{t("Subtotal")}</Label>
          <Label>{subtotalPrice}</Label>
        </Line>
        <Line>
          <Label>{t("Shipping")}</Label>
          <Label>{t("Free")}</Label>
        </Line>
        <TotalsWrapper>
          <GrandTotal>
            <p>{t("Total")}</p> <p>{price}</p>
          </GrandTotal>
          <Text>
            <span>{t("Incl. VAT")}</span>
          </Text>
        </TotalsWrapper>
      </Container>
      <DiscountContainer>
        {!showDiscountInput ? (
          <DiscountText
            onClick={() => setShowDiscountInput(!showDiscountInput)}
          >
            {t("Add promo code")}
          </DiscountText>
        ) : (
          !discountCode && (
            <>
              <form onSubmit={handleSubmit(addCode)}>
                <DiscountField
                  {...register("code", {
                    required: t("Please provide a promo code"),
                    pattern: {
                      value: /^[A-Za-z0-9_.]+$/,
                      message: t("Only characters Aa-Zz and 0-9 are allowed")
                    }
                  })}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault()
                      handleSubmit(addCode)()
                    }
                  }}
                />
                <DiscountButton
                  type="submit"
                  disabled={!isDirty || !isValid || isSubmitting}
                >
                  {t("Add")}
                </DiscountButton>
              </form>
              {errors.code && (
                <DiscountErrorLabel>{errors.code.message}</DiscountErrorLabel>
              )}
            </>
          )
        )}
        {discountCode && (
          <ActiveDiscounts>
            <p>{t("Active promo codes:")}</p>
            <ActiveDiscount>
              {discountCode}
              <button onClick={removeCode}>{t("Remove")} X</button>
            </ActiveDiscount>
          </ActiveDiscounts>
        )}
      </DiscountContainer>
    </Wrapper>
  )
}

export default SubscriptionOrderSummary
